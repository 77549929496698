import React, { useState} from "react";
// import {ReactMediaRecorder} from "react-media-recorder";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CameraIcon from "@mui/icons-material/Videocam"
import CameraOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
// import RecordIcon from '@mui/icons-material/RadioButtonChecked';
import ShareIcon from '@mui/icons-material/Share';
import {Snackbar} from "@mui/material";
// import {downloadRecording} from "../utils/dowloadRecording";
import Timer from "./timer";
const Controls = ({ stream, socket, startCall, micOn, setMicOn, cameraOn, setCameraOn }) => {
  const [open, setOpen] = useState(false);
  // const [status, setStatus] = useState(false)
  const toggleMic = () => {
    socket.emit("media-toggle", {
      media: {
       micOn: !micOn,
       cameraOn: cameraOn
      }
    });
    setMicOn(!micOn);
    const [audioTrack] = stream.getAudioTracks();
    audioTrack.enabled = !(audioTrack.enabled);
  }

  const toggleCamera = () => {
    socket.emit("media-toggle", {
      media: {
        micOn: micOn,
        cameraOn: !cameraOn
      }
    });
    setCameraOn(!cameraOn);
    const [videoTrack] = stream.getVideoTracks();
    videoTrack.enabled = !(videoTrack.enabled);
  }

  const leaveCall = () => {
   window.location.reload();
  }

  const copyLink = () => {
    const {origin, pathname} = window.location;

    navigator.clipboard.writeText(origin + pathname);
    setOpen(true);
  }

  const closeSnackbar = () => {
    setOpen(false);
  }

  return (
    <div className="control-wrapper">
      <div className="control-container">
        <button onClick={toggleMic}>
          {micOn ? <MicIcon/> : <MicOffIcon/>}
        </button>
        <button onClick={toggleCamera}>
          {cameraOn ? <CameraIcon/> : <CameraOffIcon/>}
        </button>
        <button className="leave-call" color="error" aria-label="add" onClick={leaveCall}>
          <CallEndIcon />
        </button>
        {/*<ReactMediaRecorder*/}
        {/*  onStop={downloadRecording}*/}
        {/*  screen*/}
        {/*  render={({status, startRecording, stopRecording}) => (*/}
        {/*    <button onClick={status === 'recording' ? stopRecording : startRecording}>*/}
        {/*      <RecordIcon color={status === 'recording' ? 'error' : 'inherit'}/>*/}
        {/*    </button>*/}
        {/*  )}*/}
        {/*/>*/}
        <button onClick={copyLink}>
          <ShareIcon/>
        </button>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          message="Link copied. You can send it to new participant"
          onClose={closeSnackbar}
        />
      </div>
      <Timer startCall={startCall} />
    </div>
  )
}

export default Controls;
